
  import { defineComponent, ref, watch } from "vue";
  import axios from "@/api/axios";
  import UiInput from "@/components/UI/Input";
  import UiSelect from "@/components/UI/Select";
  import Toast from "@/components/UI/Toast";
  import qs from "qs";
  import { useRouter, useRoute } from "vue-router";

  export interface IaddressOfNei {
    userName: string;
    mobile: string;
    address: string;
    postAdd: string;
    effective: boolean;
    province: string;
    city: string;
    district: string;
  }
  export default defineComponent({
    name: "addAddressOfUser",
    components: {
      UiInput,
      UiSelect,
    },
    setup() {
      const router = useRouter();
      const route = useRoute();
      const id = route.query.id || "";

      const address = ref<IaddressOfNei>({
        userName: "",
        mobile: "",
        address: "",
        postAdd: "",
        effective: true,
        province: "",
        district: "",
        city: "",
      });
      if (id) {
        axios
                .get("/M/AokeNei/AddAddressOfNei/" + id)
                .then((res) => {
                  const data = res.data.data;
                  const province = data.province || "";
                  const city = data.city || "";
                  const district = data.district || "";
                  data.effective == 1?data.effective = true:data.effective=false
                  address.value = { ...data, address: province + " " + city + " " + district };
                })
                .catch((err) => {
                  console.log(err);
                });
      }

      function back() {
        router.go(-1);
      }

      function save() {

        const data = qs.stringify({
          postId:id,
          userName: address.value.userName,
          mobile: address.value.mobile,
          postAdd: address.value.postAdd,
          effective: address.value.effective ? "1" : "0",
          province: (address.value.address && address.value.address.split(" ")[0]) || "",
          district: (address.value.address && address.value.address.split(" ")[2]) || "",
          city: (address.value.address && address.value.address.split(" ")[1]) || "",
        });
        // console.log(address.value);
        axios
                .post("/M/AokeNei/addOrEditUserAddressOfNei", data)
                .then((res) => {
                  if (res.data.success) {
                    Toast({
                      type: "success",
                      title: res.data.msg,
                      onClose: () => {
                        back();
                      },
                    });
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
      }

      return {
        address,
        save,
        back
      };
    },
  });
